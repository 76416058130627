import Modal from "bootstrap/js/src/modal";
import Collapse from "bootstrap/js/src/collapse";
import Dropdown from "bootstrap/js/src/dropdown";

import {INIT_NAVIGATION} from "./js/inits/init.navigation";
import {INIT_FANCYBOXES} from "./js/inits/init.fancybox";
import {INIT_FORM_MODAL} from "./js/inits/init.form-modal";
import {INIT_FORMS} from "./js/inits/init.forms";
import {INIT_FATHOM_FORMS} from "./js/inits/init.fathom.forms";
import {INIT_FATHOM_LINKS} from "./js/inits/init.fathom.links";

document.addEventListener('DOMContentLoaded', () => {
  setTimeout(() => {
    document.documentElement.classList.replace('no-js', 'js-rules');
  }, 90)

  INIT_NAVIGATION(Dropdown);
  INIT_FANCYBOXES()
  INIT_FORM_MODAL();
  INIT_FORMS();
  INIT_FATHOM_FORMS();
  INIT_FATHOM_LINKS();
})

export default {
  MODAL: Modal,
  COLLAPSE: Collapse
}
