const COLLAPSE_BREAKPOINT = '1400px';
const MAIN_NAV = document.querySelector('[data-main-nav]');

function CHECK_FOR_SCROLL() {
  if (window.window.scrollY > 50) {
    if (!MAIN_NAV.getAttribute('data-scrolled')) MAIN_NAV.setAttribute('data-scrolled', 'true');
  } else MAIN_NAV.removeAttribute('data-scrolled');
}

function INIT_DROPDOWNS(Dropdown) {

  const MATCH_MEDIA = window.matchMedia(`(min-width: ${COLLAPSE_BREAKPOINT})`);
  let DROPDOWN_MENU_HEIGHT;

  document.querySelectorAll('.navbar-dropdown').forEach(DROPDOWN_ITEM => {
    const MENU = DROPDOWN_ITEM.querySelector('.navbar-dropdown-menu');
    const NAV = DROPDOWN_ITEM.querySelector('.navbar-dropdown-menu-nav');

    let HAS_BREAKPOINT = false;

    DROPDOWN_ITEM.addEventListener('show.bs.dropdown', () => {
      DROPDOWN_MENU_HEIGHT = NAV.clientHeight;
      MENU.style.height = `${DROPDOWN_MENU_HEIGHT}px`;
      if (MENU.classList.contains('moving')) MENU.classList.remove('moving');
    })

    DROPDOWN_ITEM.addEventListener('hide.bs.dropdown', () => MENU.removeAttribute('style'));

    function mouseEnter() {
      new Dropdown(DROPDOWN_ITEM).show()
    }

    function mouseLeave() {
      new Dropdown(DROPDOWN_ITEM).hide()
    }

    function handleEventListeners() {
      if (MATCH_MEDIA.matches) {
        if (!HAS_BREAKPOINT) {
          DROPDOWN_ITEM.addEventListener('mouseenter', mouseEnter);
          DROPDOWN_ITEM.addEventListener('mouseleave', mouseLeave);
          HAS_BREAKPOINT = true;
        }

      } else {
        if (HAS_BREAKPOINT === 0 || HAS_BREAKPOINT) {
          DROPDOWN_ITEM.removeEventListener('mouseenter', mouseEnter);
          DROPDOWN_ITEM.removeEventListener('mouseleave', mouseLeave);
          HAS_BREAKPOINT = false;
        }
      }
    }

    handleEventListeners();
    window.addEventListener('resize', () => handleEventListeners());

  })
}

function INIT_TOGGLER() {
  const BG_OVERLAY = document.querySelector('[data-bg-overlay]');

  const COLLAPSE_NAV = document.querySelector('.navbar-collapse');
  COLLAPSE_NAV.addEventListener('show.bs.collapse', () => {
    if (!MAIN_NAV.getAttribute('data-expanded')) {
      MAIN_NAV.setAttribute('data-expanded', 'true');
      BG_OVERLAY.setAttribute('data-bg-overlay-visible', 'true');
    }
  });

  COLLAPSE_NAV.addEventListener('hide.bs.collapse', () => {
    if (MAIN_NAV.getAttribute('data-expanded')) {
      MAIN_NAV.removeAttribute('data-expanded');
      BG_OVERLAY.removeAttribute('data-bg-overlay-visible');
    }
  })
}

function INIT_NAV_LINKS() {
  const mediaQuery = window.matchMedia(`(max-width: ${COLLAPSE_BREAKPOINT})`);
  const NAV_LINKS = MAIN_NAV.querySelectorAll('header .nav-link:not(.dropdown-toggle), header .dropdown-item:not(.navbar-dropdown-headline)');

  NAV_LINKS.forEach(NAV_ITEM => {
    let currentURL = document.URL;
    const NAV_LINK = new URL(currentURL, NAV_ITEM.href),
      NAV_LINK_HASH = NAV_LINK.hash;

    NAV_ITEM.addEventListener('click', () => {
      if (mediaQuery.matches && NAV_LINK_HASH) {
        if (MAIN_NAV.getAttribute('data-expanded')) MAIN_NAV.removeAttribute('data-expanded');
      }
    })
  })
}

export function INIT_NAVIGATION(dropdown) {
  INIT_DROPDOWNS(dropdown);
  INIT_NAV_LINKS();
  CHECK_FOR_SCROLL();
  INIT_TOGGLER();

  window.addEventListener('scroll', () => CHECK_FOR_SCROLL());
}
