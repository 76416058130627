export function INIT_FATHOM_LINKS() {
  const FATHOM = typeof fathom == 'undefined' ? false : fathom;
  if (!FATHOM) return;

  const TRACK_LINKS = document.querySelectorAll('a[href^="tel"], button[href^="tel"], a[href^="mailto"], button[href^="mailto"]'),
    C2A_BUTTON = document.querySelector('.footer-cta-content > a.btn'),
    PAGE_TITLE = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

  if (C2A_BUTTON) {
    C2A_BUTTON.addEventListener('click', () => {
      FATHOM.trackEvent(`C2A – Click: ${C2A_BUTTON.title}`);
    })
  }

  TRACK_LINKS.forEach(LINK => {
    let LEAD_STRING = false;

    if (LINK.href.includes('tel')) {
      LEAD_STRING = `Lead – Call: ${LINK.href.replace('tel:', '')} (/${PAGE_TITLE})`
    } else if (LINK.href.includes('mailto')) {
      LEAD_STRING = `Lead – Mail: ${LINK.href.replace('mailto:', '')} (/${PAGE_TITLE})`
    }

    if (LEAD_STRING) LINK.addEventListener('click', () => {
      FATHOM.trackEvent(LEAD_STRING);
    })
  })
}
